import React                       from 'react';
import styled                      from '@emotion/styled';
import { Global, css }             from '@emotion/react';
import { useStaticQuery, graphql } from 'gatsby';

import BaseLayout      from '@interness/web-core/src/components/Layout/Base';
import Spacer          from '@interness/web-core/src/components/structure/Spacer/Spacer';
import GoogleRatingBox from '@interness/web-core/src/components/GoogleRatingBox/GoogleRatingBox';

import Header  from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer  from '../Footer/Footer';

const PageWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.base_color};
  margin: auto;
`;

const DefaultLayout = ({ children, pageContext, location }) => {
  const { theme, directusProject } = useStaticQuery(query);
  const globalStyles = css`
    body {
      background-color: ${theme.base_color};
    }

    a {
      color: ${theme.brand_color};
    }
  `;

  return (
    <BaseLayout pageContext={pageContext}>
      <Global styles={globalStyles}/>
      {directusProject.google_place_id && <GoogleRatingBox placeId={directusProject.google_place_id}/>}
      <div id="outer-container">
        <Header location={location}/>
        <Sidebar/>
        <PageWrapper id="page-wrap">
          <main>
            {children}
          </main>
          <Spacer height={40}/>
        </PageWrapper>
        <Footer/>
      </div>
    </BaseLayout>
  )
};

const query = graphql`
    query {
        directusProject {
            google_place_id
        }
        theme {
            ...CoreTheme
        }
    }
`;

export default DefaultLayout;
